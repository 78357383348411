import { ChannelType } from '../../models/channels/channels.model';
import Config from '../../config';
import { AxiosInstance } from '../../helpers/AxiosInstance';



const getChannels = () => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/channel/all`);
}; // channels -provider

const enabelChannel = (ChannelType: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/channel/${ChannelType}/enable`);
}

const disableChannel = (ChannelType: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/channel/${ChannelType}/disable`);
}



const getAppData = (type: ChannelType) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/channel/${type}/appdata`);
};//channel-provider

const ApplyForEarlyAcess = (data: {
    name: string,
    email: string,
    admin_id: string,
    channel_type: number,
    organization_id: string,
    organization_name: string
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/channel/wishlist/add`, data);

}



export const ProviderService = {
    getChannels,
    getAppData: getAppData,
    enabelChannel,
    disableChannel,
    ApplyForEarlyAcess
};
