import moment from "moment-timezone";
import { Contact } from "./conversations";

export class SettingsModel {
    constructor() {
    }
    BlockedContacts: Contact[];
    Settings: Setting[];
    NotificationsEnabled: boolean;
    TwoFactAuthEnabled: boolean;
    VoiceVerificationEnabled: boolean;
    AllowedWrongPasswordAttempts: number;
    UnSubscribedContacts: Contact[];
}

export class Setting {
    constructor() { }
    ID?: string;
    Name?: string;
    Code?: string;
    Value?: number ;
}

export const mapToSetting = (x: any): Setting => {
    return {
        ID: x.id,
        Name: x.name,
        Code: x.code,
        Value: x.value
    } as Setting;
}

export class TimeZoneModel {
    Name: string;
    Zone: string;
    OffsetLabel: string;
    Offset: number;
};

export const mapToTimeZoneModel = (name: string) => {
    if (!name) {
        return undefined;
    }
    let time = new Date().getTime();
    return {
        Name: name,
        Offset: moment.tz.zone(name)?.utcOffset(time),
        OffsetLabel: `UTC ${moment.tz(name).format('Z')}`,
        Zone: moment.tz.zone(name)?.abbr(time)
    } as TimeZoneModel;
}
