import { UtilityService } from './../services/UtilityService';
import { mapToPlan, PlanModel, PlanType } from './plans.model';

export class PlanHistoryModel {
    constructor() { }

    ID: string;
    PhoneNumberID: string;
    SubscriptionID: string;
    PlanID: string;
    PlanName: string;
    PlanType: PlanType;
    StartDate: string;
    EndDate: string;
    CancelDate: string;
    RenewDate: string;
    StatusType: RechargeStatus;
    IsYearPlan: boolean;
    CreatedDateTime: string;
    Plan: PlanModel;
    IsItemCancelled: boolean;
}

export const mapToPlanHistory = (x: any): PlanHistoryModel => {
    return {
        ID: x.id,
        PhoneNumberID: x.phoneNumberId,
        PlanID: x.planId,
        PlanName: x.planName,
        PlanType: x.planType as PlanType,
        StartDate: x.startDate ? UtilityService.convertDateToDay(x.startDate) : "",
        CancelDate: x.cancelDate ? UtilityService.convertDateToDay(x.cancelDate) : "",
        EndDate: x.endDate ? UtilityService.convertDateToDay(x.endDate) : "",
        RenewDate: x.renewDate ? UtilityService.convertDateToDay(x.renewDate) : "",
        StatusType: x.statusType as RechargeStatus,
        SubscriptionID: x.subscriptionId,
        IsYearPlan: x.isYearPlan,
        CreatedDateTime: UtilityService.convertDateToDay(x.createdDateTime),
        Plan: x.plan ? mapToPlan(x.plan) : {},
        IsItemCancelled: !!x.cancelDate
    } as PlanHistoryModel
}

export enum RechargeStatus {
    NONE,
    ONGOING,
    UPCOMING,
    COMPLETED,
    ON_HOLD,
    CANCELLED
}