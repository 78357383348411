import axios from 'axios';
import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import { error } from 'console';
import setToast from '../helpers/toaster.helper';
import { ToasterType } from '../components/shared/toaster/toaster.model';


const getPlans = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Plans`);
};

 const createPlans = async (data: any) => {
    var planData = await AxiosInstance.post(`${Config.API_URL}/api/Plans`, data);
    axios.post(`${Config.CHARGEBEE_INTEGRATION_API_URL}/create-plans`, { ...data, id: planData.data.id }).then((res) => {
        console.log("Sucess", res);
    }).catch((err) => {
        setToast(`Error in Chargebee: ${JSON.parse(err.response.data.error).message}`, ToasterType.DANGER, 800)
        deletePlan(planData.data.id)
            .then((res) => {
                setToast(`Plan removed from Db due to chargebee error, refresh to see latest plans`, ToasterType.WARNING, 1000)
            })
            .catch((error) => {
                console.log("Error", error);
            })
    })
};

const updatePlans = async (data: any) => {
    var planData = await AxiosInstance.put(`${Config.API_URL}/api/Plans`, data);
};

const getPlanById = (id: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Plans/${id}`);
};

const DisablePlans = (id: string) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Plans/${id}`);
};

const deletePlan = (id: string) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Plans/${id}`);
};

export const PlansService = {
    getPlans,
    createPlans,
    updatePlans,
    getPlanById,
    DisablePlans,
    deletePlan,
};