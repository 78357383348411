
export interface ChannelModel {
    Label: string;
    Text?: string;
    Icon?: any;
    Type?: ChannelType;
    Status?: number;
    UpdatedAt?: string;
}

export const mapToChannelModel = (x) => {
    return {
        Label: x.name,
        Icon: "",
        Type: x.id,
        Status: x.status,
        UpdatedAt: x.updated_at
    }
}

export enum ChannelType {
    SMS = 0,
    FACEBOOK,
    INSTAGRAM,
    WHATSAPP,
    LIVE_CHAT,
    GOOGLE_BUSINESS,
    MESSENGER,
}

// export interface ChannelAccountModel {
//     ID: string;
//     Title: string;
//     Description: string;
//     Icon: string;
//     Type: ChannelType;
// }

export enum ChannelAccountStatus {
    CONNECTED = 1,
    DISCONNECTED,
    VALIDATION_PENDING,
    DELETED,
}

export enum WidgetTitle {
    NAME_AND_LOGO = 1,
    NAME_ONLY,
    AGENT_ONLY
}

export enum VisitorLoginType {
    ALLOW_LOGIN_AND_ANONYMOUS_LOGIN = 1,
    ALLOW_LOGIN_ONLY,
    ALLOW_ANONYMOUS_LOGIN_ONLY
}

export interface PremiumFeatures {
    WidgetTitleType: WidgetTitle;
    WidgetColor: string;
    VisitorLoginType: VisitorLoginType;
    LoginFields: any;
    AnonymousUserSettings: string;
    IsAllowFalkonBranding: boolean;
    IsShowRating: boolean;
    Channels: { ChannelType: number, URL: string }[];
    HeaderTextColor: string;
}

export interface ChannelAccountModel {
    ID: string;
    ChannelType: ChannelType;
    Status: ChannelAccountStatus;
    UpdatedDate: string;
    CreatedDate: string;
    Name: string;
    NickName: string;
    UpdatedByID: string;
    CreatedByID: string;
    ChannelRedirectUrl: string;
    AccountId: string;
    ChannelIcon: string;
    ChannelWelcomeMessage: string;
    ChannelWelcomeMessageStatus: string;
    PremiumFeatures?: PremiumFeatures;
    OrganizationID: string;
}

export const mapToPremiumFeatures = (x: any): PremiumFeatures => {
    return {
        WidgetTitleType: x.widget_type,
        WidgetColor: x.widget_color,
        VisitorLoginType: x.visitor_login_type,
        LoginFields: !!x.login_fields && x.login_fields.map((x, index) => { return { Name: x.field_name, Type: x.field_type, Required: x.isRequired ? "YES" : "No", Id: index } }),
        AnonymousUserSettings: x.anonymous_user_settings,
        IsAllowFalkonBranding: x.isAllowFalkonBranding,
        IsShowRating: x.isIncludeQuestionnaire,
        Channels: !!x.channels && x.channels.map(x => { return { ChannelType: x.channel_type, URL: x.url } }),
        HeaderTextColor: x.text_color,
    }
}

export const mapToChannelGrid = (x: any): ChannelAccountModel => {
    return {
        ID: x.id,
        ChannelType: x.channel,
        Status: x.status,
        UpdatedDate: x.updated_at,
        UpdatedByID: x.updated_by,
        CreatedByID: x.created_by,
        CreatedDate: x.created_at,
        Name: x.name,
        NickName: x.nick_name,
        ChannelRedirectUrl: x.channel_url ? x.channel_url : "",
        AccountId: x.account_id,
        ChannelIcon: x.data?.channel_icon ? x.data.channel_icon : "",
        ChannelWelcomeMessage: x.data?.welcome_message ? x.data.welcome_message : "",
        ChannelWelcomeMessageStatus: x.data?.welcome_message_status ? x.data.welcome_message_status : "",
        PremiumFeatures: mapToPremiumFeatures(x.premium_features),
        OrganizationID: x.organization_id
    } as ChannelAccountModel
}

export interface TenantModel {
    Id: string;//orgnaization id
    Name: string;//org name
    Email: string;//user email id
    App_Id: ChannelType;//"fc_dev_628b545d1e14ea1823c5180f"
}

export enum ChannelDeliveryStatus {
    SENDING = 1,
    SENT,
    SENT_FAILED,
    DELIVERED,
    READ,
}

export enum ChannelProviderStatus {
    ACTIVE = 1,
    INACTIVE
}

export const Fb24HourWindowTags = {
    CONFIRMED_EVENT_UPDATE: " Confirmed Event Update",
    CUSTOMER_FEEDBACK: "Customer Feedback",
    ACCOUNT_UPDATE: "Account Update",
    HUMAN_AGENT: "Agent Interaction",
}

export const ChannelWelcomeStatusModel = {
    ACTIVE: "1",
    INACTIVE: "2"
}

export const DaysInAWeek = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
}

export interface BussinessHoursModel {
    DAY: string;
    OPEN_TIME: number;
    CLOSE_TIME: number;
    SELECTED: boolean;
}

export interface ChannelOrganizationModel {
    ID: string;
    Name: string;
    Email: string;
    Status: number;
    User_Count: string;
    Created_At: string;
    Updated_At: string;
    Plan_Type: number;
    Added_By: string;
}

export const mapToChannelOrganization = (x): ChannelOrganizationModel => {

    return {
        ID: x.organization_id,
        Name: x.name,
        Email: x.email,
        Status: x.status,
        User_Count: x.user_count,
        Created_At: x.created_at,
        Plan_Type: x.plan_type,
        Updated_At: x.updated_at,
        Added_By: x.added_by
    }
}

export enum ChanneTicketingStatus {
    Open = 1,
    Closed,
    Following
}

export enum ChanneTicketingPriority {
    Low = 1,
    Medium,
    High
}