import { ProductType } from './add-phone-number.model';
import { mapToPlanHistory, PlanHistoryModel } from './transaction-history.model';

export class PhoneNumberModel {
    constructor() { }

    ID: string;
    CreatedDateTime: string;
    CurrentPlanID: string;
    Name: string;
    IsDeleted: boolean;
    IsGracePeriod: boolean;
    OrganizationID: string;
    PhoneNumber: string;
    Provider: number;
    Plans: PlanHistoryModel[];
    Users: number;
    ValidityDateTime: string;
    Status: PhoneNumberStatus;
    ProductType: ProductType;
    CurrentPlan: string;
}

export const mapToPhoneNumber = (x: any): PhoneNumberModel => {
    return {
        ID: x.id,
        CreatedDateTime: x.createdDateTime,
        CurrentPlanID: x.currentPlanId,
        Name: x.name,
        IsDeleted: x.isDeleted,
        OrganizationID: x.organizationId,
        PhoneNumber: x.number,
        Plans: x.plans?.map(mapToPlanHistory),
        Provider: x.provider,
        Users: x.users,
        ValidityDateTime: x.validityDateTime,
        Status: x.status as PhoneNumberStatus,
        ProductType: x.productType as ProductType,
        CurrentPlan: x.plans?.find(y => y.planId === x.currentPlanId)?.planName
    } as PhoneNumberModel;
};

export class ProviderModel {
    constructor() { }
    ID: number;
    Name: string;
}

export const mapToProvider = (x: any): ProviderModel => {
    return {
        ID: x.id,
        Name: x.name,
    } as ProviderModel;
};
export class PhoneNumberDetails {
    constructor() { }

    Id: string;
    Name: string;
    PhoneNumber: string;
    OrganizationID: string;
    ProviderID: number;
}
export const mapToPhoneNumberDetails = (x: any): PhoneNumberDetails => {
    return {
        Id: x.id,
        Name: x.name,
        PhoneNumber: x.number,
        OrganizationID: x.organizationId,
        ProviderID: x.provider,
    } as PhoneNumberDetails;
};

export enum PhoneNumberStatus {
    NONE,
    IN_PROGRESS,
    PAYMENT_DUE,
    ACTIVE,
    INACTIVE
}



