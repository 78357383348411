import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';


const getAllFeature = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Features`);
};

const updateFeature = (data: any) => {
    return AxiosInstance.put(`${Config.API_URL}/api/Features`, data);
};

const DisableFeature = (id: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/Features/id?id=${id}`);
};

export const FeatureService = {
    getAllFeature,
    updateFeature,
    DisableFeature
};