import Config from "../config";

export class TemplateModel {
    ID: string;
    Title: string;
    Message: string;
    Media?: MediaModel;
    UpdatedDateTime: string;
    CreatedByID: string;
    IsPrivate: boolean | -1;
}

export enum FacebookTemplateType {
    BUTTON_TEMPLATE = 1,
    FEEDBACK_TEMPLATE = 2,
    MEDIA_TEMPLATE = 3,
    PRODUCT_TEMPLATE = 4,
    RECEIPT_TEMPLATE = 5,
    GENERIC_TEMPLATE = 6,
    BASIC_TEXT_TEMPLATE = 7,
    BASIC_MEDIA_TEMPLATE = 8
}

export enum MediaType {
    IMAGE = 1,
    VIDEO = 2,
    AUDIO = 3,
    FILE = 4,
}

export enum FacebookButtonType {
    URL_BUTTON = 1,
}

export interface ChannelTemplateButtonModel {
    Id: number;
    ButtonName: string;
    ButtonError: string;
    Url: string;
    UrlError: string;
}

export class MediaModel {
    ID: string;
    Name: string;
    Url: string;
    ContentType: string;
}

export const mapToTemplate = (x: any): TemplateModel => {
    return {
        ID: x.id,
        Title: x.title,
        Message: x.message,
        UpdatedDateTime: x.updatedDateTime,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
        CreatedByID: x.createdById,
        IsPrivate: x.isPrivate,
    } as TemplateModel
}

export class ChannelTemplates {
    ID: string;
    Title: string;
    ChannelType: number;
    CreatedAt: string;
    UpdatedAt: string;
    Url: string;
    MediaType: string;
    Message: string;
    Filename: string;
    ChannelAccountID?: string;
}

export const mapToChannelTemplateModel = (x: any) => {
    return {
        ID: x._id,
        Title: x.name,
        ChannelType: x.channel,
        CreatedAt: x.created_at,
        UpdatedAt: x.updated_at,
        Url: x.payload?.Value,
        MediaType: x.payload?.media_type,
        Message: x.payload?.text ? x.payload?.text : "",
        Filename: x.payload?.filename,
        ChannelAccountID: x?.channel_account,
    }
}

export class FacebookTemplateModel {
    ID: string;
    Title: string;
    ChannelType: number;
    CreatedAt: string;
    UpdatedAt: string;
    TemplateType: string;
    Payload: any;
    ChannelAccountID: string;
}

export const mapToFaceBookTemplateModel = (x: any) => {

    return {
        ID: x._id,
        Title: x.name,
        ChannelType: x.channel,
        CreatedAt: x.created_at,
        UpdatedAt: x.updated_at,
        TemplateType: x.payload?.template_type,
        Payload: x.payload,
        ChannelAccountID: x.channel_account
    }
}

export class FacebookTemplatePayloadModel {
    Buttons: any;
    Elements: any;
    TemplateType: string;
    Text: string;
}

export class FacebookTemplatePayloadButtonModel {
    ButtonName: string;
    ButtonUrl: string;
    ButtonType: String;
}

export class FacebookTemplatePayloadElementModel {
    MediaType: string;
    MediaUrl: String;
}

export const MapToFacebookTemplatePayloadModel = (x: any) => {
    return {
        Buttons: x.buttons.map(MapToFacebookTemplatePayloadButtonModel),
        Elements: x.elements.map(MapToFacebookTemplatePayloadElementModel),
        TemplateType: x.template_type,
        Text: x?.text
    }
}

export const MapToFacebookTemplatePayloadButtonModel = (x: any) => {
    return {
        ButtonName: x.title,
        ButtonUrl: x.url,
        ButtonType: x.type
    }
}

export const MapToFacebookTemplatePayloadElementModel = (x: any) => {
    return {
        MediaType: x.media_type,
        MediaUrl: x.url
    }
}