import axios from "axios"
import Config from "../config"


const createHostPage = (data: any) => {
    return axios.post(`${Config.CHARGEBEE_INTEGRATION_API_URL}/create-host-page`, data)
}

const addonRecharge = (data: any, isRecurring: boolean) => {
    return axios.post(`${Config.CHARGEBEE_INTEGRATION_API_URL}/addon-recharge?recurring=${isRecurring}`, data)
}

const cancelSubscription = (subscriptionId: string) => {
    return axios.post(`${Config.CHARGEBEE_INTEGRATION_API_URL}/cancel-subscription?id=${subscriptionId}`);
}

const cancelItem = (itemId: string, phonePlanIDs: string[], phoneId: string, subscriptionId: string, quantity: number) => {
    return axios.post(`${Config.CHARGEBEE_INTEGRATION_API_URL}/cancel-item`, { phonePlanIDs, quantity, itemId, phoneId, subscriptionId });
}


export default { createHostPage, addonRecharge, cancelSubscription, cancelItem };