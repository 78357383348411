import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';

const getGroups = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/GroupContacts/Groups`);
};

const getGroupById = (id: any) => {
  return AxiosInstance.get(`${Config.API_URL}/api/GroupContacts/${id}`);
};

const updateGroup = (data: any) => {
  return AxiosInstance.put(`${Config.API_URL}/api/GroupContacts`, data);
};

const createGroup = (data: any) => {
  return AxiosInstance.post(`${Config.API_URL}/api/GroupContacts`, data);
};

const deleteGroup = (id: any, isChecked: boolean) => {
  return AxiosInstance.delete(`${Config.API_URL}/api/GroupContacts/group/${id}?isContactsDelete=${isChecked}`);
};

const deleteContact = (id: any, contactId: any) => {
  return AxiosInstance.delete(`${Config.API_URL}/api/GroupContacts/${id}/${contactId}`);
};


const groupsMassAction = (action: string, data: any) => {
  return AxiosInstance.post(`${Config.API_URL}/api/GroupContacts/MassAction?action=${action}`, data);
}

const shareGroup = (groupId: string, phoneId: any[], groupName: string) =>{
  return AxiosInstance.post(`${Config.API_URL}/api/GroupContacts/${groupId}/Share/${phoneId}?name=${groupName}`, phoneId);
};

export const GroupContactService = {
  getGroups,
  getGroupById,
  updateGroup,
  createGroup,
  deleteGroup,
  deleteContact,
  groupsMassAction,
  shareGroup,
};