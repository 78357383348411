import { createStore } from 'redux'

function messageReducer(state = [], action: any) {
    state = action.data;
    return state;
};

export const MessageReducer = createStore(messageReducer);


