import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import { RuleModel } from '../models/auto-alert.model';


const getAutoAlertKeywords = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/MessageRules/Keywords`);
};

const createAutoAlertKeyword = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/MessageRules/Keywords`, data);
};

const updateAutoAlertKeyword = (data: any) => {
    return AxiosInstance.put(`${Config.API_URL}/api/MessageRules/Keywords`, data);
};

const deleteAutoAlertKeyword = (keywordId: any) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/MessageRules/Keywords/${keywordId}`);
};

const pauseOrResumeAutoAlertKeyword = (keywordId: any, data: any) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/MessageRules/Keywords/${keywordId}/Status`, data);
}

const getAutoAlertLogs = (page: number, pageCount: number) => {
    return AxiosInstance.get(`${Config.API_URL}/api/MessageRules/Logs?skip=${(page - 1) * pageCount}&take=${pageCount}`);
};

const getAutoAlertPrivateRules = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/MessageRules/Rules`);
};

const getAutoAlertGlobalRules = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/MessageRules/AdminRules`);
};

const createAutoAlertRule = (data: any, fileName: string, fileType: any) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.post(`${Config.API_URL}/api/MessageRules/Rules`, data, config);
};

const updateAutoAlertRule = (data: RuleModel[], fileName?: string, fileType?: any) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.put(`${Config.API_URL}/api/MessageRules/Rules`, data, config);
};

const deleteAutoAlertRule = (ruleId: string) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/MessageRules/Rules/${ruleId}`);
};

const updateRuleStatus = (ruleId: string) => {
    return AxiosInstance.patch(`${Config.API_URL}/api/MessageRules/Rules/${ruleId}/Status`);
}

const shareRuleToPhoneNumber = (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/MessageRules/Share`, data);
};

export const AutoAlertRulesService = {
    getAutoAlertPrivateRules,
    getAutoAlertGlobalRules,
    getAutoAlertKeywords,
    createAutoAlertKeyword,
    updateAutoAlertKeyword,
    deleteAutoAlertKeyword,
    pauseOrResumeAutoAlertKeyword,
    getAutoAlertLogs,
    deleteAutoAlertRule,
    updateRuleStatus,
    shareRuletoPhoneNumber: shareRuleToPhoneNumber,
    createAutoAlertRule,
    updateAutoAlertRule,
};