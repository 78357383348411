import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import Config from "../config";
import { AxiosInstance } from "../helpers/AxiosInstance";

const getAllSecureFiles = (id: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/completefilesList`, id);
}

const deleteSecureFile = (data: any) => {
    return AxiosInstance.delete(`${Config.API_SECOND_URL}/securefileshare/api/v1/deleteFile`, { data })
}

const getSelectedFile = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/fileDetails`, data)
}

const downloadSecureFile = (data: any) => {
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer' as ResponseType
    };

    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/downloadFile`, data, config);
}

const getUrlLink = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/getFileURL`, data)
}

const getFileAccessList = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/getFileAccessList`, data)
}

const removeFillAccess = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/manageFileAccess`, data)
}

const addFillAccess = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/manageFileAccess`, data)
}

const uploadFile = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/uploadFile`, data)
}

const verifyEmail = (data: any) => {
    return axios.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/verifyEmail`, data)
}

const verifyOTP = (data: any) => {
    const config: AxiosRequestConfig = {
        responseType: 'arraybuffer' as ResponseType
    };
    return axios.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/verifyOTP`, data, config)
}

const getAllSecureFileNameAndLinks = (data: any) => {
    return AxiosInstance.post(`${Config.API_SECOND_URL}/securefileshare/api/v1/concisefilesList`, data)
}

export const SecureFileService = {
    getAllSecureFiles,
    deleteSecureFile,
    getSelectedFile,
    downloadSecureFile,
    getUrlLink,
    getFileAccessList,
    removeFillAccess,
    addFillAccess,
    uploadFile,
    verifyEmail,
    verifyOTP,
    getAllSecureFileNameAndLinks
}