import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';



const getTemplates = () => {
    return AxiosInstance.get(`${Config.API_URL}/api/Template/Templates`);
};

const createTemplate = (data: any, fileName: string, fileType: any) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.post(`${Config.API_URL}/api/Template/Templates`, data, config);

};

const updateTemplates = (data: any, fileName: string, fileType: any) => {
    const config = fileName && fileType ? {
        headers: {
            "file-name": fileName,
            "file-type": fileType
        }
    } : undefined;
    return AxiosInstance.put(`${Config.API_URL}/api/Template/Templates`, data, config);
};

const getTemplateById = (Id: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Template/Templates/${Id}`);
};

const deleteTemplate = (Id: string) => {
    return AxiosInstance.delete(`${Config.API_URL}/api/Template/Templates/${Id}`);
};

export const TemplateService = {
    getTemplates,
    createTemplate,
    updateTemplates,
    getTemplateById,
    deleteTemplate,
};
