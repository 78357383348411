import { ProductType } from "./add-phone-number.model";

export class PlanModel {
    PlanID: string;
    Type: PlanType | -1;
    Name: string;
    Price: number;
    YearlyPrice: number;
    Features: PlanFeature[];
    IsDeleted: boolean;
    Footnote?: string;
    Dashboard: number;
    GracePeriod: number;
    Product: ProductType | -1;
    Country: string;
    TierOccurrence?: TierOccurrence | 0;
    IsPrivate?: boolean;
    SelectedOrganizations?: []
};

export const mapToPlan = (x: any): PlanModel => {
    return {
        PlanID: x.id,
        Type: x.planType,
        Name: x.name,
        Price: x.price,
        YearlyPrice: x.yPrice,
        Features: x.features.map(mapToPlanFeature),
        Color: x.color,
        IsDeleted: x.isDeleted,
        Footnote: x.fNotes,
        Dashboard: x.dashboardType,
        GracePeriod: x.gracePeriod,
        Product: x.productType,
        Country: x.country,
        TierOccurrence: x.occurrenceType,
        IsPrivate: x.isPrivate,
        SelectedOrganizations: x.organizationId
    } as PlanModel
};

export const UNLIMITED = -1;

export class PlanFeature {
    ID: string;
    Label: string;
    Code: string;
    Type: FeatureType | -1;
    DisplayOrder: number;
    Validity?: number;
    Value?: string;
};

export const mapToPlanFeature = (x: any): PlanFeature => {
    return {
        ID: x.id,
        Label: x.label,
        Type: x.featureType,
        DisplayOrder: x.displayOrder,
        Validity: x.validity,
        Code: x.code,
        Value: x.value === "Unlimited" ? "-1" : x.value
    } as PlanFeature
}

export class PhoneNumberPlan {
    constructor(id: string, name: string, number: string, users: string, status: string, currentplan: string, planvaliditiy: string, productType: number, addons: { name: string, count: number }[]) {
        this.ID = id;
        this.Name = name;
        this.PhoneNumber = number;
        this.Users = users;
        this.CurrentStatus = status;
        this.CurrentPlan = currentplan;
        this.PlanValidity = planvaliditiy;
        this.ProductType = productType;
        this.Addons = addons;

    }
    ID: string;
    Name: string;
    PhoneNumber: string;
    Users: string;
    CurrentStatus: string;
    CurrentPlan: string;
    PlanValidity: string;
    ProductType: ProductType;
    Addons: { name: string, count: number }[];
}

export enum PlanType {
    TIER = 1,
    ADD_ON
}
export enum TierOccurrence {
    ONE_TIME = 1,
    RECURRING
}
export enum FeatureType {
    MAIN = 0,
    ADDITIONAL,
    PURCHASE
}

export class AddOnModel extends PlanFeature {
    Type: FeatureType = FeatureType.MAIN;
    Price: number;
}

export enum NewPhoneNumberType {
    LANDLINE = 1, BRAND_NEW, PORT
}

export enum TierModificationType {
    ADD, EDIT, DUPLICATE
}
