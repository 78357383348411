import { AppRoutes } from "../components/routes/route.path"
import Config from "../config"
import { AxiosInstance } from "../helpers/AxiosInstance"
import { mapToChannelUser } from "../models/users.model"
import { AuthService } from "./AuthService"
import store from '../stores/app-store';
import { SetUserRoleId } from "../actions"
import { UtilityService } from "./UtilityService"

const getCurrentUser = async (userEmail) => {
    const dispatch = store.dispatch;

    return await AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/user/details/${userEmail}?timezone=${UtilityService.getDefaultTimeZone()?.Name}`).then((res) => {
        let userData = res.data;
        res.data.roleId = res.data.role
        delete res.data.role
        localStorage.setItem("current-user", JSON.stringify(userData))
        localStorage.setItem("Timezone", res.data.timezone.selected_timezone)

        SetUserRoleId(dispatch, Number(res.data.roleId));

        // localStorage.setItem("channelcurrent-user", JSON.stringify(userData))

    }).catch((err) => {

    })
}

const getLoggedInUserRole = () => {
    var userString = localStorage.getItem("current-user");
    // var userString = localStorage.getItem("channelcurrent-user");
    if (userString) {
        var user = JSON.parse(userString?.toString()!);
        return user && user.roleId ? parseInt(user.roleId) : 0;
    }
}

const hasSysAdminRole = () => {
    var userString = localStorage.getItem("current-user");
    if (userString) {
        var user = JSON.parse(localStorage.getItem("current-user")?.toString()!);
        // var user = JSON.parse(localStorage.getItem("channelcurrent-user")?.toString()!);
        return user.roleId == 1;
    }
}

const getCurrentUserFromLocalStorage = () => {
    var user = !!localStorage.getItem("current-user") && JSON.parse(localStorage.getItem("current-user")?.toString() ?? "");
    if (!!user) {
        return mapToChannelUser(user);
    }
    else {
        localStorage.clear();
        window.location.replace(AppRoutes.CHANNELS_SIGN_IN);
    }
}

const getAllUsers = (orgid: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/user/organization/${orgid}/users`);
};

const sendInvitation = (data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/user/send/invitation`, data);
};

const updateUserInvitation = (data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/user/edit/invitation`, data);
};

const disableUser = (userid: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/user/account/${userid}/disable`);
};

const deleteUser = (userid: string, CurrentUserID: string) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/user/delete/${userid}/${CurrentUserID}`);
};

const updateProfile = (userid: string, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/user/update/${userid}`, data);
};

const getDashboardMetrics = (orgid: string, days: number) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/user/dashboard/${orgid}?days=${days}`);
};

const assignTicket = (orgId: string, participantId: string, data: { "assigned_to": string, "admin": string }) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgId}/participant/${participantId}/ticket/assign`, data);
};

const updateTicketPriority = (orgId: string, participantId: string, data: { "priority": number, "admin": string }) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgId}/participant/${participantId}/ticket/priority`, data);
};

const updateTicketStatus = (orgId: string, participantId: string, data: { "status": number, "admin": string }) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgId}/participant/${participantId}/ticket/status`, data);
};

const handleNotifcation = (userId: string, enable: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/user/notification/${userId}/${enable}`);
};

const updateUserSignature = (userId: string, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/user/signature/${userId}`, data);
}

const updateUserTimeZone = (userId: string, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/user/timezone/${userId}`, data);
}

const handleEmailNotifcation = (orgId: string, enable: boolean) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/user/${orgId}/${enable}`);
};

const getStripeCustomerPortalession = () => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/stripe/customer-portal-session`);
}

const getUserPlanDetails = () => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/stripe/customer-active-subscription`);
}


export const ChannelUserService = {
    getCurrentUser,
    getLoggedInUserRole,
    hasSysAdminRole,
    getCurrentUserFromLocalStorage,
    getAllUsers,
    sendInvitation,
    updateUserInvitation,
    disableUser,
    deleteUser,
    updateProfile,
    getDashboardMetrics,
    assignTicket,
    updateTicketPriority,
    updateTicketStatus,
    handleNotifcation,
    updateUserSignature,
    updateUserTimeZone,
    handleEmailNotifcation,
    getStripeCustomerPortalession,
    getUserPlanDetails,
}