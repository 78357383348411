
export class OrganizationModel {
    constructor() {
    }
    ID: string;
    Name: string;
    PhoneNumberCount: number;
    Reference: string;
    UserCount: number;
    IsDeleted: boolean;
    CreatedDateTime: string;
}

export const mapToOrganization = (x: any): OrganizationModel => {
    return {
        ID: x.id,
        Name: x.name,
        PhoneNumberCount: x.phones,
        Reference: x.reference,
        UserCount: x.users,
        IsDeleted: x.isDeleted,
        CreatedDateTime: x.createdDateTime
    } as OrganizationModel
}

export class OrganizationDetails {
    constructor() {

    }
    ID: string;
    Name: string;
    Email: string;
    ZipCode: string;
    Address: string;
    MobileNumber: string;
    Industry: string;
    HouseNumber: string;
    Reference: string;
    City: string;
    State: string;
    Country: string;
}

export const mapToOrganizationDetails = (x: any): OrganizationDetails => {
    return {
        ID: x.id,
        Name: x.name,
        Reference: x.reference,
        MobileNumber: x.number,
        Industry: x.industry || "0",
        Address: x.address,
        HouseNumber: x.aptNumber,
        City: x.city,
        ZipCode: x.zip,
        Email: x.email,
        State: x.state,
        Country: x.country
    } as OrganizationDetails
}