import {
    ToasterTimeout,
    ToasterType,
} from '../components/shared/toaster/toaster.model'
import { setToasts, showToast } from '../reducers/reducer'
import { v4 as uuid } from 'uuid'
import store from '../stores/app-store'

const setToast = (
    text: string,
    type: ToasterType,
    timeout: ToasterTimeout = 'default'
) => {
    try {
        let id = uuid()
        store.dispatch(
            setToasts({
                id,
                text,
                type,
                timeout,
            })

        )

        setTimeout(
            () =>
                store.dispatch(
                    showToast({ id })
                ),
            500
        )
    } catch (e) {
        console.log(e)
    }
}

export default setToast
