export class ProfileModel {
    constructor() {
    }
    ID: string;
    FullName: string;
    ImageReferenceId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    PhoneNumberID: string;
    OrgId: string;
    OrganizationName: string;
    RoleID: string;
}

export const mapToProfile = (x: any): ProfileModel => {
    return {
        ID: x.id,
        FullName: x.firstName + " " + x.lastName,
        ImageReferenceId: x.imageReferenceId,
        FirstName: x.firstName,
        LastName: x.lastName,
        Email: x.email,
        PhoneNumber: x.phoneNumber,
        PhoneNumberID: x.phoneNumberId,
        OrgId: x.organizationId,
        OrganizationName: "",
        RoleID: x.roleId,
    } as ProfileModel
}

