import { configureStore, } from '@reduxjs/toolkit';
import reducer from '../../src/reducers/reducer'

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
	devTools: true
})
export type RootState = ReturnType<typeof store.getState>

export default store;
