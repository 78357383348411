import { FeatureType } from './plans.model';

export class FeaturesModel {
    constructor() { }
    ID: string;
    Name: string;
    Label: string;
    Type: FeatureType;
    IsDeleted: boolean;
    CreatedDateTime: string;
    DisplayOrder: number;
    Code: string;
}

export const mapToFeature = (x: any): FeaturesModel => {
    return {
        ID: x.id,
        Name: x.name,
        Label: x.label,
        Type: x.type as FeatureType,
        IsDeleted: x.isDeleted,
        CreatedDateTime: x.createdDateTime,
        DisplayOrder: x.displayOrder,
        Code: x.code
    } as FeaturesModel;
}