import { ChannelType } from '../../models/channels/channels.model';
import { AxiosInstance } from '../../helpers/AxiosInstance';
import Config from '../../config';




const getAllParticipants = (orgID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/participant/all`);
};//channel-conversation

const getAllParticipantsByChannelId = (orgID: string, Channel_Id) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/participant/both/${Channel_Id}/1`);
};//channel-conversation

const getParticipantMessages = (participantID: string, page: number) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/messages/${participantID}/history?p=${page}`);
};//channel-conversation-history

const getAllChannelScheduleMessages = (tenantId: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/schedule/all/${tenantId}`)
};//get all schedule messages

const getChannelMessageById = (participantID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/schedule/${participantID}`)
}

const sendChannelTextMessage = (participantID: string, type: ChannelType, value: number, data: {
    participant_id: string,
    user_id: string,
    text: string,
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/messages/${participantID}/${type}/send/${value}`, data);
};//send message

const sendChannelMediaMessage = (participantID: string, mediaType: string, channelType: ChannelType, user_ID: string, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/messages/${participantID}/${channelType}/mms/${mediaType}/${user_ID}`, data);
};//send multimedia message 

const sendChannelBroadcastTextMessage = (data: {
    channel: ChannelType,
    account_id: string,
    participant_ids: string[],
    text: string
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/broadcast/send`, data);
};//send channel broadcast message

const sendChannelBroadcastMultiMediaMessage = (participantIDs: string, mediaType: string, channelType: ChannelType, user_ID: string, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/broadcast/mms/${channelType}/${mediaType}/${participantIDs}/${user_ID}/send`, data);
};//send channel broadcast multimedia message

const sendChannelTextScheduleMessage = (data: {
    type: number,
    channel: ChannelType,
    account_id: string,
    participant_ids: string[],
    next_send_at: number,
    tag: number,
    schedule_window_type: number,
    text: {
        type: string,
        value: string,
    }
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/broadcast/schedule`, data);
};//send channel broadcast schedule message

// const sendChannelScheduleMessage(accountID: string, participantID: string, channelType: ChannelType, data: {
//     message: {
//         type: string,
//         value: string,
//         payload: null
//     },
//     type: number,
//     next_send_at: number
// }) {
//     return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/schedule/${accountID}/${participantID}/${channelType}/add`, data)
// };//send schedule message

const sendChannelMediaScheduleMessage = (participantIDs: string, mediaType: string, channelType: ChannelType, user_ID: string, Schedule_Window: number, Time: number, Channel_Id: string, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/broadcast/schedule/mms/${channelType}/${mediaType}/${participantIDs}/${user_ID}/1/${Channel_Id}/${Time}/${Schedule_Window}/send`, data);
};//send channel broadcast schedule message

const enableChannelScheduleMessageById = (ScheduleMessageID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/schedule/${ScheduleMessageID}/enable`);
}// enable channel schedule reply

const disableChannelScheduleMessageById = (ScheduleMessageID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/schedule/${ScheduleMessageID}/disable`);
}// disable channel schedule reply

const deleteChannelScheduleMessageById = (ScheduleMessageID: string) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/schedule/delete/${ScheduleMessageID}`);
}// delete channel schedule reply

const deleteChannelMessages = (participantID: string, ids: string[]) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/messages/${participantID}/delete`, {
        data: ids
    });
};//delete message

const deleteChannelConversation = (orgID: string, participantID: string) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/participant/${participantID}`);
};//delete-channel-conversation

const setArchiveChannelConversation = (orgID: string, participantID: string, isArchived: boolean, userId: string) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/participant/${participantID}`, {
        isArchived,
        userId
    });
};

const unReadChannelConversation = (orgId: string, participantID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/${orgId}/participant/${participantID}/unread`);
}

const readChannelConversation = (orgID: string, participantID: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/participant/${participantID}/readall`);
}


const addChannelAutomatedReply = (tenantId: string, channelType: ChannelType, data: {
    channel_accounts: string[],
    automation_name: string,
    conditions: {
        condition_name: number,
        condition_values: any,
    }[],
    condition_status: number,
    actions:
    {
        action_name: string,
        action_value: {
            type: string,
            value: any,
            payload?: {
                file_name: string
            }
        }
    }[]
    ,
    trigger_once: boolean
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${channelType}/add`, data);
} //add channel automated reply

const getAutomatedReplyById = (tenantId: string, id: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${id}`);
}// get channel automated reply by id

const UpdateAutomatedReplyById = (tenantId: string, AutomatedReplyId: string, data: {
    channel_accounts: string[],
    automation_name: string,
    conditions: {
        condition_name: number,
        condition_values: any,
    }[],
    condition_status: number,
    actions:
    {
        action_name: string,
        action_value: {
            type: string,
            value: any,
            payload?: {
                file_name: string
            }
        }
    }[]
    ,
    trigger_once: boolean
}) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${AutomatedReplyId}`, data);
}// update channel automated reply by id

const getAllAutomatedRepliesByPageId = (tenantId: string, pageId: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/account/${pageId}`);
}// get all channel automated replies 

const enableAutomatedReplyById = (tenantId: string, automationReplyId: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${automationReplyId}/enable`);
}// enable channel automated reply

const disableAutomatedReplyById = (tenantId: string, automationReplyId: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${automationReplyId}/disable`);
}// disable channel automated reply

const deleteAutomatedReplyById = (tenantId: string, automationReplyId: string, channelType: number) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${automationReplyId}/${channelType}`);
}// delete channel automated reply

const IncreaseAutomatedReplyPriority = (tenantId: string, automationReplyId: string, channelType: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${automationReplyId}/${channelType}/increase/priority`);
}// increase channel automated reply priority

const DecreaseAutomatedReplyPriority = (tenantId: string, automationReplyId: string, channelType: number) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/automated/${tenantId}/${automationReplyId}/${channelType}/decrease/priority`);
}// decrease channel automated reply priority


//Templates

const MMSTemplate = (tenant: string, channel: number, mmstype: string, mmsname: string, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/template/${tenant}/${channel}/${mmstype}/mms?name=${mmsname}`, data);
}// create media template for livechat


//Templates
const BasicTexttemplate = (tenantId: string, channel: number, data: {
    name: string,
    type: any,
    payload: {
        template_type: string,
        text?: string
    }
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/template/${tenantId}/${channel}`, data);
}// create text template for livechat

const createFacebookTemplate = (tenantId: string, channelType: number, data: {
    name: string,
    type: any,
    payload: {
        template_type: string,
        text?: string,
        elements?:
        {
            media_type: string,
            url: string
        }[]
        buttons?:
        {
            type: string,
            title: string,
            url?: string,
            payload?: string
        }[]
    }
    channel_account: string
}
) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/template/${tenantId}/${channelType}`, data);
}//create facebook template

const getPhotosFromFacebook = (accountId: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/template/media/all/${accountId}`);
}// get media links from a facebook page

const getAllChannelTemplates = (tenantId: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/template/all/${tenantId}`);
}// get all templates


const getAllTemplates = (id: string, channelType: number) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/template/saved/${id}/${channelType}`)
}

const sendFacebookTemplateMessage = (id: string, channelType: number, data: any) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/messages/${id}/${channelType}/template`, data)
}

const deleteChannelTemplate = (id: string) => {
    return AxiosInstance.delete(`${Config.FALKON_CONNECT_API}/api/v1/template/${id}`)
}

const editFacebookTemplate = (tempId: string, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/template/${tempId}`, data)
}

const editChannelTemplate = (tempId: string, channeltype: number, data) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/template/short/${channeltype}/${tempId}`, data)
}

const IsTyping = (MessageParticipantId: string, data: {
    user_typing: boolean,
    business_typing: boolean,
    user_name?: string
}) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/livechat/participant/${MessageParticipantId}/typing`, data)
}

const UpdateChannelTicketingStatusOpen = (participant_id: string) => {
    return AxiosInstance.patch(`${Config.FALKON_CONNECT_API}/api/v1/livechat/widget/ticket/${participant_id}/reopen`)
}

export const ChannelConversationService = {
    getAllParticipants,
    getParticipantMessages,
    deleteChannelMessages,
    deleteChannelConversation,
    setArchiveChannelConversation,
    unReadChannelConversation,
    readChannelConversation,
    sendChannelTextMessage,
    sendChannelMediaMessage,
    // sendChannelScheduleMessage,
    getAllChannelScheduleMessages,
    getChannelMessageById,
    sendChannelBroadcastTextMessage,
    sendChannelBroadcastMultiMediaMessage,
    addChannelAutomatedReply,
    getAutomatedReplyById,
    getAllAutomatedRepliesByPageId,
    enableAutomatedReplyById,
    disableAutomatedReplyById,
    deleteAutomatedReplyById,
    IncreaseAutomatedReplyPriority,
    DecreaseAutomatedReplyPriority,
    UpdateAutomatedReplyById,
    sendChannelTextScheduleMessage,
    sendChannelMediaScheduleMessage,
    getAllParticipantsByChannelId,
    enableChannelScheduleMessageById,
    disableChannelScheduleMessageById,
    deleteChannelScheduleMessageById,
    getPhotosFromFacebook,
    createFacebookTemplate,
    getAllChannelTemplates,
    MMSTemplate,
    BasicTexttemplate,
    getAllTemplates,
    sendFacebookTemplateMessage,
    deleteChannelTemplate,
    editFacebookTemplate,
    editChannelTemplate,
    IsTyping,
    UpdateChannelTicketingStatusOpen,
};