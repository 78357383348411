export class RoleModel {
    ID: Role;
    Name: string;
    RoleType: number;
    IsDeleted: boolean;
}

export const mapToRole = (data: any): RoleModel => {
    return {
        ID: data.id as Role,
        Name: data.name,
        RoleType: data.roleType,
        IsDeleted: data.isDeleted
    } as RoleModel;
}

export enum Role {
    SYSTEM_ADMIN = 1, ORGANIZATION_ADMIN, USER, MANAGER
}