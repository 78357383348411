import React, { PureComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ClearToast } from '../../../actions'
import { AppState } from '../../../reducers/models'
import { ToasterModel, ToasterType } from './toaster.model'
import Config from '../../../config'
import { ToasterSucessIcon, ToasterWarningIcon, ToasterErrorIcon, ToasterInfoIcon, ToasterCancelIcon } from '../../../icon'
import * as selector from '../../../selectors/selectors'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


interface Props extends ToasterModel {
    Index: number
}

const ToasterComponent = (props: Props) => {
    const dispatch = useDispatch()

    const [isTimeout, setIsTimeout] = useState<NodeJS.Timeout>();
    const [start, setStart] = useState<number>();
    const [remaining, setRemaining] = useState<number>(typeof props.Timeout === 'number' ? props.Timeout * 1000 : Config.TOASTER_TIMEOUT);
    const [isTimeoutNever, setIsTimeoutNever] = useState<boolean>(props.Timeout === 'never')


    useEffect(() => {
        ClearTimeout()
        StartTimeout()
        return () => {
            ClearTimeout()
        }
    }, [])

    const HandleMouseEnter = (event: any) => {
        PauseTimeout()
    }

    const PauseTimeout = () => {
        if (!isTimeoutNever) {
            let Remaining = remaining - (Date.now() - start)
            setRemaining(Remaining)
            ClearTimeout()
        }
    }
    const ClearTimeout = () => {
        if (isTimeout && !isTimeoutNever) {
            clearTimeout(isTimeout)
            let Timeout = 0 as unknown as NodeJS.Timeout
            setIsTimeout(Timeout)
        }
    }

    const HandleMouseLeave = (event: any) => {
        if (!isTimeout) {
            StartTimeout()
        }
    }

    const StartTimeout = () => {
        if (!isTimeoutNever) {
            setStart(Date.now())
            let Timeout = setTimeout(() => ClearToast(dispatch, props.ID), remaining < 0 ? 0 : remaining)
            setIsTimeout(Timeout)
        }
    }

    const GetColorVariable = () => {
        switch (props.Type) {
            case ToasterType.SUCCESS:
                return `primary-${ToasterType.SUCCESS.toString().toLowerCase()}`

            case ToasterType.WARNING:
                return `primary-${ToasterType.WARNING.toString().toLowerCase()}`

            case ToasterType.DANGER:
                return `primary-${ToasterType.DANGER.toString().toLowerCase()}`

            case ToasterType.INFO:
                return `primary-${ToasterType.INFO.toString().toLowerCase()}`

            default:
                return ''
        }
    }

    const GetIcon = () => {
        switch (props.Type) {
            case ToasterType.SUCCESS:
                return (
                    <ToasterSucessIcon
                        className={'w-3 my-auto'}
                    />
                )

            case ToasterType.WARNING:
                return (
                    <ToasterWarningIcon
                        className={'w-3 my-auto'}
                    />
                )

            case ToasterType.DANGER:
                return (
                    <ToasterErrorIcon className={'w-3 my-auto'} />
                )

            case ToasterType.INFO:
                return (
                    <ToasterInfoIcon className={'w-3 my-auto'} />
                )

            default:
                return ''
        }
    }
    const HandleClose = () => {
        ClearTimeout()
        ClearToast(dispatch, props.ID)
    }


    return (
        <div
            onMouseEnter={HandleMouseEnter}
            onMouseLeave={HandleMouseLeave}
            style={{ zIndex: 9999 }}
            className={`toaster h5-regular t-${props.Index * (Math.ceil((props.Text || '').length / 29) * 3 + 3)}
            ${props.Hide ? 'close' : 'open'} bg-toaster-${GetColorVariable()} color-toaster-font d-flex p-1 my-1`}
        >
            {GetIcon()}
            <span className={'my-auto ml-1 mr-3'}>{props.Text}</span>
            <ToasterCancelIcon
                className={'my-auto ml-auto w-3 c-pointer d-hover'}
                onClick={HandleClose}
            />
        </div>
    )
}


export default ToasterComponent;
