import { Role } from "./roles.model";

export class UserModel {
    constructor() { }

    ID: string;
    CreatedDateTime: string;
    Email: string;
    FirstName: string;
    ImageReferenceID: string;
    IsDeleted: boolean;
    LastLoggedIn: string;
    LastName: string;
    OrganizationID: string;
    Password: string;
    PhoneNumber: string;
    PhoneNumberID: string;
    RoleID: Role;
    UserStatus: number;
}

export enum UserStatus {
    ACTIVE = 1,
    LOCKED,
    PENDING,
}

export enum ChannelUserStatus {
    ACTIVE = 1,
    INACTIVE,
    PENDING,
    DELETED,
    DISABLED
}

export class ChannelUserModel {
    constructor() { }

    ID: string;
    Email: string;
    FirstName: string;
    LastName: string;
    FullName: string;
    OrganizationID: string;
    OrganizationName: string;
    Password?: string;
    RoleID: number;
    UserStatus: number;
    IsProfileUpdated?: boolean;
    IsWidgetAdded?: boolean;
    ImageReferenceId: string;
    SignUpMethod: number;
    AddedBy: string;
    Signature: { IsEnabledSign: boolean, Value: string };
    Timezone: { IsDefaultTimezone: boolean, SelectedTimeZone: string };
    NotificationEnabled: boolean;
    CreatedDateTime: string;
    UpdatedDateTime: string;
    Channel_Wishlist: number[];
    EmailNotifcation: boolean;
}

export const mapToChannelUser = (x: any): ChannelUserModel => {
    return {
        ID: x.id,
        Email: x.email,
        FirstName: x.first_name,
        LastName: x.last_name,
        FullName: x.first_name + " " + x.last_name,
        OrganizationID: x.organization_id,
        OrganizationName: x.organization_name,
        Password: x?.password ? x.password : "",
        RoleID: !!x.roleId ? x.roleId : x.role,
        UserStatus: x.status,
        IsProfileUpdated: x?.user_profile_update ? x.user_profile_update : false,
        IsWidgetAdded: x?.widget_setup ? x.widget_setup : false,
        ImageReferenceId: x.profile_image,
        SignUpMethod: x.sign_up_method,
        AddedBy: x.added_by,
        Signature: { IsEnabledSign: x.signature.is_enable_sign, Value: x.signature.value },
        Timezone: { IsDefaultTimezone: x.timezone.is_default_timezone, SelectedTimeZone: x.timezone.selected_timezone },
        NotificationEnabled: x.web_notification,
        CreatedDateTime: x.created_at,
        UpdatedDateTime: x.updated_at,
        Channel_Wishlist: x.channel_wishlist,
        EmailNotifcation: x?.email_notification,
    }
}

export interface ChannelPLanModel {
    PlanId: string,
    PLanName: string,
    Status: string,
    Price: any,
    Features: string[],
    Quantity: 1,
    AddOns: null | [],
    IsReccuring: boolean,
    IsMonthly: boolean,
    IsYearly: boolean,
    Created_At: string,
    Last_Updated_At: string,
    Start_Date: string,
    End_Date: string
}

export const mapToChannelModel = (x): ChannelPLanModel => {
    return {
        PlanId: x.plan_Id,
        PLanName: x.plan_name,
        Status: x.plan_status,
        Price: x.price,
        Features: x.features,
        Quantity: x.quantity,
        AddOns: null,
        IsReccuring: x.recurring,
        IsMonthly: x.monthly,
        IsYearly: x.yearly,
        Created_At: x.plan_created_at,
        Last_Updated_At: x.plan_updated_at,
        Start_Date: x.plan_started_at,
        End_Date: x.plan_validity,
    }
}

