import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';


const getOrganizations= ()=> {
    return AxiosInstance.get(`${Config.API_URL}/api/Organizations`);
};

const getOrganizationsGridData= ()=> {
    return AxiosInstance.get(`${Config.API_URL}/api/Organizations/grid`);
};

const getOrganizationById= (orgId: string) => {
    return AxiosInstance.get(`${Config.API_URL}/api/Organizations/${orgId}`);
};

const updateOrganization= (data: any)=> {
    return AxiosInstance.put(`${Config.API_URL}/api/Organizations`, data);
};

const createOrganization= (data: any) => {
    return AxiosInstance.post(`${Config.API_URL}/api/Organizations`, data);
};

const deleteOrganizations= (id: any)=> {
    return AxiosInstance.delete(`${Config.API_URL}/api/Organizations/${id}`);
};


export const OrganizationService = {
    getOrganizations,
    getOrganizationsGridData,
    getOrganizationById,
    updateOrganization,
    createOrganization,
    deleteOrganizations
};








