import { AxiosInstance } from '../../helpers/AxiosInstance';
import Config from '../../config';


const getAllChannelContacts = (orgID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/all`);
}; //channel-contacts

const addCustomFields = (orgID: string, data: { name: string, type: string }) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/custom/add`, data)
}; //add-channel-contact-grid-customfield

const UpdateContactCustomFieldValue = (orgID: string, ContactId: string, data: any) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/${ContactId}`, data)
}

const getAllCustomFields = (orgID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/custom`);
}

const deleteCustomFields = (orgID: string, data: { fieldids: string[] }) => {
    return AxiosInstance.post(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/custom/delete`, data as any);
}

const updateCustomFields = (orgID: string, data: any) => {
    return AxiosInstance.put(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/custom`, data);
}

const getChannelContactByID = (orgID: string, ParticipantID: string) => {
    return AxiosInstance.get(`${Config.FALKON_CONNECT_API}/api/v1/${orgID}/contact/${ParticipantID}`);
}



export const ChannelContactServices = {
    getAllChannelContacts,
    addCustomFields,
    getAllCustomFields,
    deleteCustomFields,
    UpdateContactCustomFieldValue,
    updateCustomFields,
    getChannelContactByID,
}