import Config from '../config';
import { AxiosInstance } from '../helpers/AxiosInstance';
import { MessageService } from './MessageService';


const getContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts`);
};

const getContactById = (id) => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/${id}`);
};

const updateContact = (data) => {
  return AxiosInstance.put(`${Config.API_URL}/api/Contacts`, data);
};

const createContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts`, data);
};

const deleteContact = (id) => {
  return AxiosInstance.delete(`${Config.API_URL}/api/Contacts/${id}`);
};

const uploadFile = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/uploadFile`, data);
}
const outlookFile = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/Import?source=Outlook`, data);
}
const importContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/bulkInsert`, data);
}
const importCorrectContact = (data, save) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/bulkInsert?saveRecords=${save}`, data);
}

const unBlockAll = (data) => {
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/UnBlockAll`, data);
}

const blockUnBlockNumber = (number, isBlocked) => {
  MessageService.blockUnBlockNumber(number, isBlocked);
  number = number.replace(/\+/g, "");
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/${number}/SetBlocked/${isBlocked}`);
};
const getBlockedContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/Blocked`);
}

const getUnsubscribeContacts = () => {
  return AxiosInstance.get(`${Config.API_URL}/api/Contacts/UnSubscribed`);
}
const setUnreadMessageCount = (number, count) => {
  // MessageService.setUnreadMessageCount(number, count);
  number = number.replace(/\+/g, "");
  return AxiosInstance.patch(`${Config.API_URL}/api/Contacts/${number}/SetRead/${count}`).then(res => {
    return res;
  });
};


const contactsMassActionDelete = (action, data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/MassActions?action=${action}`, data);
}

const contactsMassAction = (action, data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/MassAction?action=${action}`, data);
}

const shareContact = (data) => {
  return AxiosInstance.post(`${Config.API_URL}/api/Contacts/Share`, data);
}
function editGroupchatName({ Id, name }) {
  return AxiosInstance.patch(`${Config.API_URL}/api/Messages/UpdateGroupChat?groupChatId=${Id}&groupChatName=${name}`);
}


export const ContactService = {
  getContacts,
  getContactById,
  updateContact,
  createContact,
  deleteContact,
  importContact,
  importCorrectContact,
  uploadFile,
  blockUnBlockNumber,
  getBlockedContacts,
  setUnreadMessageCount,
  unBlockAll,
  getUnsubscribeContacts,
  contactsMassAction,
  shareContact,
  contactsMassActionDelete,
  outlookFile,
  editGroupchatName
};