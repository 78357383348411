
import Config from "../../config";
import { ChannelDeliveryStatus, ChannelType } from "../channels/channels.model";
import { UserModel, UserStatus } from "../../models/users.model";
import { AppState } from "../../reducers/models";
import { UtilityService } from "../../services/UtilityService";

// export class ConversationModel {
//     constructor() { }
//     IsOutbound: boolean;
//     FromPhoneNumber?: string;
//     PhoneNumber: string;
//     MaskedPhoneNumber: string;
//     Media?: MediaModel;
//     Message: string | null;
//     MessageDateTime: string;
//     SenderID: string;
//     UnReadCount: number;
//     Contact?: Contact;
//     IsPinned: boolean;
//     IsBlocked: boolean;
//     IsUnsubscribed: boolean;
//     DeliveryStatus: DeliveryStatus;
//     IsMuted: boolean;
//     GroupChat?: GroupChatModel;
//     GroupChatId?: string;
//     Type: ChannelType;


// }

export enum ConversationType {
    SMS = 0,
    FACEBOOK,
    INSTAGRAM,
    WATSAPP,
    LIVE_CHAT,
    GOOGLE_BUSSINESS_MESSAGES,
}

export enum LiveChatUserLoggedInStatus {
    LoggedIn = 1,
    LoggedOut,
    CheckingStatus
}



export enum DeliveryStatus {
    NONE, PENDING, SENT, FAILED, RECEIVED, SENDING,
}

export class Contact {
    constructor() {
    }

    ID: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Name: string;
    PhoneNumber: string;
    MaskedPhoneNumber: string;
    Groups?: Group[];
    IsBlocked: boolean;
    IsUnsubscribed: boolean;
    IsDeleted: boolean;
    IsPublic: boolean;
    IsPinned: boolean;
    UnReadCount: number;
    CreatedDateTime: string;
    Errors?: ContactError;
    ContactStatus: ContactStatus
    Source: string;
    CreatedById: string;
    UpdatedById: string

}

export enum ContactStatus {
    ACTIVE = 1,
    BLOCKED,
    UNSUBSCRIBED,
}

export class ContactError {
    constructor() {
    }
    FirstName: string;
    ContactLastName: string;
    PhoneNumber: string;
    ContactEmail: string
}

export class Group {
    constructor() {
    }

    ID: string;
    Name: string;
    CreatedDateTime: string;
    ContactsCount: number;
    TotalActiveContacts: number;
    TotalBlockedContacts: number;
    TotalUnsubscribedContacts: number;
    Contacts?: Contact[];
    IsDeleted?: boolean;
}

export class ContactType {
    IsPinned: boolean;
    IsArchived: boolean;
    CreatedDateTime: string;
    MutedTill: string;
    PhoneNumber: string;
}

export class MediaModel {
    constructor() {
    }

    ID: string;
    Name: string;
    Url: string;
    ContentType: string;
}

export class GroupChatModel {
    ID: string;
    PhoneNumbers: string[];
    Name: string;
    UnReadCount: number;
}



export const mapToGroupChat = (groupchat: any): GroupChatModel => {
    return {
        ID: groupchat.id,
        PhoneNumbers: groupchat.phoneNumbers,
        Name: groupchat.name,
        UnReadCount: groupchat.unReadCount,
    } as GroupChatModel;
};

export class DeletedGroupContactData {

    DeletedContacts: Contact[];
    DeletedContactsCount: number;
    NonDeletedContacts: NonDeletedIds[];
    TotalContactsCount: number;
}

export class NonDeletedIds {
    ContactId: string;
    GroupIds: string[];
}

export class TypingDataModel {
    ReceiverPhoneNumber: string;
    Action: TypeAction;
    UserID: string;
}
export class OutLookResponse {
    flag: boolean;
    totalContacts: number;
    ignoredContacts: number;
    savedContacts: number;
    existingContacts: number;
    error: string;
    loadContacts: boolean
}



export enum MassActionStatus {
    DeleteGroupsStarted = "DeleteGroupsStarted",
    RefreshContactsAndGroups = "RefreshContactsAndGroups",
    MassActionStarted = "MassActionStarted",
    RefreshContacts = "RefreshContacts"
}


export enum TypeAction {
    START_TYPING = "StartTyping",
    STOP_TYPING = "StopTyping",
}

export enum MessageCategory {
    Basic_Message = 1,
    Automated_Message = 2,
    Welcome_Message = 3,
    Ticketing_Message = 4,
    Livechat_Loggedin_Update_Message = 5
}
export const mapToDeletedGroupContactData = (data: any): DeletedGroupContactData => {
    return {
        DeletedContacts: data.deletedContacts.map(mapToContact),
        DeletedContactsCount: data.deletedContactsCount,
        NonDeletedContacts: data.map(mapToNonDeletedIds),
        TotalContactsCount: data.totalContactsCount
    }
}

export const mapToNonDeletedIds = (data: any): NonDeletedIds => {
    return {
        ContactId: data.contactId,
        GroupIds: data.groupIds.map(x => x)
    }
}

export const mapToConverstion = (x: any): ConversationModel => {
    return {
        ID: x.id,
        PhoneNumber: !x.isOutbound && !!x.groupChat?.id ? x.groupChat?.id : x.phoneNumber,
        FromPhoneNumber: !!x.groupChat?.id || !!x.groupChatId ? x.phoneNumber : "",
        MaskedPhoneNumber: UtilityService.maskPhoneNumberOnLength(x.phoneNumber),
        Message: x.message,
        MessageDateTime: x.messageDateTime,
        IsPinned: x.pinned,
        IsArchived: x.archived,
        IsUnsubscribed: x.isUnSubscribed,
        IsBlocked: x.isBlocked,
        IsOutbound: x.isOutbound,
        SenderID: x.senderId,
        UnReadCount: x.unReadCount,
        Media: x.media ? { ID: x.media?.id, Name: x.media?.name, ContentType: x.media?.contentType, Url: `${Config.API_URL}/api/File/${x.media?.id}/view` } as MediaModel : null,
        DeliveryStatus: x.statusId as DeliveryStatus,
        IsMuted: false,
        GroupChat: x.groupChat ? { ID: x.groupChat.id, PhoneNumbers: x.groupChat.phoneNumbers, Name: x.groupChat.name, UnReadCount: x.groupChat.unReadCount } as GroupChatModel : null,
        GroupChatId: x.groupChatId,
        Type: ChannelType.SMS
    } as ConversationModel;
}

export const mapToContact = (contact: any): Contact => {
    return {
        ID: contact.id || contact.contactId,
        Name: (contact.firstName === null && contact.lastName === null) ? '' : contact.name ? contact.name.trim() : `${contact.firstName} ${contact.lastName}`.trim(),
        Email: contact.email || "",
        IsUnsubscribed: contact.isUnsubscribed,
        IsBlocked: contact.isBlocked,
        PhoneNumber: contact.phoneNumber,
        CreatedDateTime: contact.createdDateTime,
        FirstName: contact.firstName !== null ? contact.firstName : '',
        LastName: contact.lastName !== null ? contact.lastName : '',
        MaskedPhoneNumber: contact.phoneNumber ? UtilityService.maskPhoneNumberOnLength(contact.phoneNumber) : "",
        Errors: contact.errors as ContactError,
        IsDeleted: !!contact.isDeleted,
        UnReadCount: contact.unReadCount,
        ContactStatus: contact.isUnsubscribed ? ContactStatus.UNSUBSCRIBED : contact.isBlocked ? ContactStatus.BLOCKED : ContactStatus.ACTIVE,
        Source: contact.source,
        CreatedById: contact.createdById,
        UpdatedById: contact.updatedById

    } as Contact;
};


export const mapToContactType = (x: any): ContactType => {
    return {
        IsPinned: x.isPinned,
        IsArchived: x.isArchived,
        PhoneNumber: x.phoneNumber,
        CreatedDateTime: x.createdDateTime,
        MutedTill: x.muteDateTime
    } as ContactType;
};

export const mapToUser = (x: any): UserModel => {
    return {
        ID: x.id,
        CreatedDateTime: x.createdDateTime,
        Email: x.email,
        FirstName: x.firstName,
        ImageReferenceID: x.imageReferenceId,
        IsDeleted: x.isDeleted,
        LastLoggedIn: x.lastLoggedIn,
        LastName: x.lastName,
        OrganizationID: x.organizationId,
        Password: x.password,
        PhoneNumber: x.phoneNumber,
        PhoneNumberID: x.phoneNumberId,
        RoleID: parseInt(x.roleId),
        UserStatus: x.lastLoggedIn === null ? UserStatus.PENDING : x.isDeleted ? UserStatus.LOCKED : UserStatus.ACTIVE
    } as UserModel;
};

export const mapToGroup = (x: any): Group => {
    return {
        ID: x.id,
        Name: x.name,
        CreatedDateTime: x.createdDateTime,
        ContactsCount: x.contacts ? x.contacts.length : x.count,
        Contacts: x.contacts ? x.contacts.map(mapToContact) : [],
        IsDeleted: x.isDeleted,
        TotalActiveContacts: 0,
        TotalBlockedContacts: 0,
        TotalUnsubscribedContacts: 0,
    } as Group;
}

export const sortConversations = (cons: ConversationModel[]): ConversationModel[] => {
    return [
        ...cons.filter(x => x.IsPinned).sort((current: ConversationModel, previous: ConversationModel) => current.IsPinned && previous.IsPinned ? 0 : current.IsPinned && !previous.IsPinned ? -1 : 1),
        ...cons.filter(x => !x.IsPinned).sort((current: ConversationModel, previous: ConversationModel) => new Date(current.MessageDateTime) > new Date(previous.MessageDateTime) ? -1 : new Date(current.MessageDateTime) < new Date(previous.MessageDateTime) ? 1 : 0)
    ];
};

export const sortContacts = (contacts: Contact[]): Contact[] => {
    return [
        ...contacts.sort((current: Contact, previous: Contact) => new Date(current.CreatedDateTime) > new Date(previous.CreatedDateTime) ? -1 : new Date(current.CreatedDateTime) < new Date(previous.CreatedDateTime) ? 1 : 0),

    ];
};

export const updateContactTypesInConversations = (conversations: ConversationModel[], contactTypes: ContactType[]): ConversationModel[] => {
    conversations.forEach(x => { x.IsArchived = false; x.IsPinned = false; x.IsMuted = false; });
    contactTypes.forEach((contactType: ContactType) => {
        let con = conversations.find(x => ((x.PhoneNumber)?.replace("+", "") === contactType.PhoneNumber?.replace("+", "")) || x.GroupChat?.ID === contactType.PhoneNumber);
        if (con) {
            con.IsArchived = contactType.IsArchived;
            con.IsPinned = contactType.IsPinned;
            con.IsMuted = new Date(contactType.MutedTill) >= UtilityService.getPreferredTimeZoneTimeNow();
            if (con.Contact) {
                con.Contact.IsPinned = contactType.IsPinned;
            }
        }
    });
    return sortConversations(conversations);
}

export const updateContactsInConversations = (conversations: ConversationModel[], contacts: Contact[]): ConversationModel[] => {
    contacts.forEach((contact: Contact) => {
        let con = conversations.find(x => UtilityService.unmaskPhoneNumber(x.PhoneNumber) === UtilityService.unmaskPhoneNumber(contact.PhoneNumber));
        if (con) {
            con.Contact = contact;
        }
    });

    return sortConversations(conversations);
}

export const pushConversation = (conversations: ConversationModel[], contacts: Contact[], newCons: ConversationModel[]): ConversationModel[] => {
    let cons = [...conversations];
    newCons.forEach(newCon => {
        let oldCon = cons.find(x => !!newCon.GroupChat?.ID ? x.GroupChat?.ID === newCon.GroupChat?.ID : UtilityService.unmaskPhoneNumber(x.PhoneNumber) === UtilityService.unmaskPhoneNumber(newCon.PhoneNumber));

        if (oldCon) {
            newCon.IsPinned = oldCon.IsPinned;
            newCon.IsMuted = oldCon.IsMuted;
            newCon.IsBlocked = oldCon.IsBlocked;
            newCon.Contact = oldCon.Contact;
            newCon.IsArchived = oldCon.IsArchived;
        }
        else {
            newCon.Contact = contacts.find(x => UtilityService.unmaskPhoneNumber(x.PhoneNumber) === UtilityService.unmaskPhoneNumber(newCon.PhoneNumber));
        }
        cons = [...cons.filter(x => !!newCon.GroupChat?.ID ? x.GroupChat?.ID !== newCon.GroupChat?.ID : UtilityService.unmaskPhoneNumber(x.PhoneNumber) !== UtilityService.unmaskPhoneNumber(newCon.PhoneNumber)), newCon]
    })

    return sortConversations(cons);
}

export const pushConversationInContact = (contacts: Contact[], newCons: ConversationModel[]): Contact[] => {
    let cons = [...contacts]
    newCons.forEach(newCon => {
        let contact = contacts.find(x => UtilityService.unmaskPhoneNumber(x.PhoneNumber) === UtilityService.unmaskPhoneNumber(newCon.PhoneNumber));
        if (contact) {
            contact.IsPinned = newCon.IsPinned;
            contact.IsUnsubscribed = newCon.IsUnsubscribed;
        }
        cons = [...contacts]
    })

    return sortContacts(cons);
}

export const pushHistory = (history: { [key: string]: ConversationModel[] }, newCons: ConversationModel[]): { [key: string]: ConversationModel[] } => {
    newCons.forEach(newCon => {
        let historyToUpdate = history[!!newCon.GroupChat?.ID ? newCon.GroupChat?.ID : newCon.PhoneNumber.replace("+", '')];
        if (historyToUpdate && historyToUpdate.length) {
            historyToUpdate = historyToUpdate.filter(x => (x.ID !== newCon.ID) && x.ID)
            historyToUpdate.push(newCon);
            history[!!newCon.GroupChat?.ID ? newCon.GroupChat?.ID : newCon.PhoneNumber.replace("+", '')] = historyToUpdate;
        }
    })
    return { ...history };
}

export const pushChannelHistory = (history: { [key: string]: ChannelMessageItem[] }, newCons): { [key: string]: ChannelMessageItem[] } => {
    newCons.history.forEach(newCon => {
        let historyToUpdate = history[newCon.ConversationID];
        if (historyToUpdate?.length) {
            historyToUpdate = historyToUpdate.filter(x => (x.ID !== newCon.ID) && x.ID)
            historyToUpdate.push(newCon);
            history[newCon.ConversationID] = historyToUpdate;
        }
    })
    return { ...history };
}

export const handlePushContacts = (state: AppState, action): AppState => {
    let { Conversations, Contacts, AllContacts } = state;
    action.payload.forEach((contact: Contact) => {
        let _conversation = Conversations.find(x => x.PhoneNumber === contact.PhoneNumber || x.Contact?.ID === contact.ID);
        let _contact = Contacts.find(x => x.ID === contact.ID);
        if (_conversation) {
            _conversation.UnReadCount = contact.UnReadCount;
            _conversation.IsBlocked = contact.IsDeleted ? _conversation.IsBlocked : contact.IsBlocked;
        }

        if (_conversation?.Contact?.ID === contact.ID && (contact.IsDeleted || contact.MaskedPhoneNumber !== _conversation?.Contact?.MaskedPhoneNumber)) {
            _conversation.Contact = undefined;
        }

        if (_contact) {
            if (_contact.IsBlocked !== contact.IsBlocked) {
                _contact.IsBlocked = contact.IsBlocked;
                _contact.CreatedDateTime = contact.CreatedDateTime;
            }
            _contact.UnReadCount = contact.UnReadCount;
            _contact.IsDeleted = contact.IsDeleted;
        }
    });

    Contacts = Contacts.map(con => ({
        ...con,
        ...action.payload.find(payload => (payload.ID === con.ID))
    }))

    Contacts = Contacts.filter(e => (!e.IsDeleted))

    AllContacts = AllContacts.map(con => ({
        ...con,
        ...action.payload.find(payload => payload.ID === con.ID)
    }))

    Contacts = [...Contacts, ...action.payload.filter(x => !Contacts.map(y => y.ID).includes(x.ID))]
    AllContacts = [...AllContacts, ...action.payload.filter(x => !AllContacts.map(y => y.ID).includes(x.ID))]

    return {
        ...state,
        Conversations: [...Conversations],
        Contacts: sortContacts([...Contacts]),
        AllContacts: sortContacts([...AllContacts]),
        Groups: updateContactsInGroup(state.Groups, Contacts),
        Settings: {
            ...state.Settings,
            BlockedContacts: Contacts.filter((x: Contact) => x.IsBlocked),
            UnSubscribedContacts: Contacts.filter((x: Contact) => x.IsUnsubscribed)
        }
    };
}


export const updateContactsInGroup = (groups: Group[], contacts: Contact[]): Group[] => {
    if (contacts.length) {
        groups.forEach(x => {
            let newContacts = [...contacts.filter(y => x.Contacts?.map(z => z.ID).includes(y.ID))];
            x.Contacts = newContacts;
            x.TotalActiveContacts = newContacts.filter(y => (!y.IsBlocked && !y.IsUnsubscribed)).length;
            x.TotalBlockedContacts = newContacts.filter(y => y.IsBlocked).length;
            x.TotalUnsubscribedContacts = newContacts.filter(y => y.IsUnsubscribed).length;
            x.ContactsCount = newContacts.length
        })
    }
    return [...groups];
}

export const updateGroupsInContacts = (contacts: Contact[], groups: Group[]): Contact[] => {
    const updatedContacts = contacts.map(x => {
        const matchingGroups = [...groups.filter(y => y.Contacts?.map(z => z.ID).includes(x.ID))]
        return { ...x, Groups: matchingGroups };
    });

    return updatedContacts;
}


export const handlePushGroupChat = (state: AppState, action): AppState => {
    let { Conversations } = state;

    action.payload.map((x) => {
        let con = Conversations.find(y => y.GroupChat?.ID === x.id)
        if (con) {
            con.GroupChat.UnReadCount = x.unReadCount;
        }
    })
    return {
        ...state, Conversations: [...Conversations]
    }
}

export const PushGroupChatName = (state: AppState, id, name): AppState => {

    let { Conversations } = state;

    let con = Conversations.find(y => y.GroupChat?.ID === id)
    if (con) {
        con.GroupChat.Name = name;
    }
    return {
        ...state, Conversations: [...Conversations]
    }
}


// Convesation Tabs

export class ConversationTabsModel {
    constructor() {
    }

    id: number;
    label: any;
    value: string;
    isLoading: boolean;
    data: ConversationCardModel[] | null;
    onClick?: (value:any) => void;
    maxSize?:string;
}

export interface IConversationCardFlags {
    IsActive: boolean;
    // Index: number;
    // OptionsRef: Element | ((element: Element) => Element) | null | undefined;
}

export type ConversationCardModel = IConversationCardFlags & ConversationModel | ChannelConversationItem & IConversationCardFlags;

// export type ConversationCardModel = ConversationModel

export enum LiveChatStatus {
    CONNECTED = 1,
    DISCONNECTED,
    VALIDATION_PENDING
}

export enum ChannelConversationStatus {
    ACTIVE = 1,
    INACTIVE,
    CONVERSATION_WINDOW_EXPIRED,
    CONVERSATION_DELETED,
    CHANNEL_DELETED,
    LIVECHAT_USER_CREATED,
}

export class BaseConversationItem {
    ID: string;
    SenderID: string;
    Message: string;
    MessageDateTime: string;
    Type: ChannelType;
    UnReadCount: number;
    IsArchived: boolean;
}

export class ChannelMessage extends BaseConversationItem {
    MessageType: string;
    MessageID?: string;
    IsOutbound: boolean;
    MessagePayload: any[] | null;
}

export class BaseChannelConversationItem extends ChannelMessage {
    Status: ChannelConversationStatus;
    UpdatedBy: string;
    Name: string;
    UserName: string;
    AccountId: string;
    FirstName: string;
    LastName: string;
    MessageStatus: ChannelDeliveryStatus;
    ProfilePicLink: string;
    Type: number;
    LastInboundMessageActivity: String;
    OnlineStatus: boolean
}

export class ChannelConversationItem extends BaseChannelConversationItem {
    ProfilePicLink: string;
    Ticket: {
        Assigned_To: string,
        Priority: number,
        Status: number,
        History: { Action: string, Time: string, By: string }[]
    };
    UserTyping: boolean;
    BussinessTyping: boolean;
    SoundEnabled: boolean;
    TypingBy?: string;
}

export class ChannelMessageItem extends ChannelMessage {
    ID: string;
    SenderID: string;
    CreatedAt: string;
    IsOutbound: boolean;
    IsUnread: boolean;
    ConversationID: string;
    DeliveryStatus: ChannelDeliveryStatus;
    DeliverAt: string;
    MessageCategory?: MessageCategory;
    ReadAt: string;
    Reaction?: {
        Type: string;
        Emoji: string;
    }
}


export const mapToChannelMessage = (x: any): ChannelMessageItem => {
    return {
        ID: x.id,
        SenderID: x.sender_id,
        // Text: x.text,
        Message: x.Message.value,
        MessageType: x.Message.type,
        MessagePayload: x.Message.payload,
        CreatedAt: x.created_at,
        IsOutbound: !x.inbound,
        ConversationID: x.participant_id,
        IsUnread: x.is_unread,
        DeliveryStatus: x.status as ChannelDeliveryStatus,
        DeliverAt: x.delivered_at,
        ReadAt: x.read_at,
        MessageCategory: x?.message_category,
        Reaction: x.reaction ? {
            Type: x.reaction?.reaction,
            Emoji: x.reaction?.emoji
        } : undefined
    } as ChannelMessageItem;
}

export const mapToChannelConversationItem = (x: any): BaseChannelConversationItem => {
    return {
        ID: x.id,
        Name: x.name,
        UserName: x.username,
        AccountId: x.account_id,
        FirstName: x.data.first_name,
        LastName: x.data.last_name,
        ProfilePicLink: x.data.profile_pic,
        Status: x.status as ChannelConversationStatus,
        UpdatedBy: x.updated_by,
        MessageDateTime: !!x.messages[0]?.created_at ? x.messages[0]?.created_at : x.message_activity, //x.messages[0].created_at need to veify {x.updated_at,   x.messages[0].created_at}
        Message: x.messages[0]?.Message?.value,
        MessageType: x.messages[0]?.Message?.type,
        MessageID: x.messages[0]?.id,
        IsOutbound: !x.messages[0]?.inbound,
        MessagePayload: x.messages[0]?.Message?.payload,
        Type: x.channel_type,
        IsArchived: x.is_archived,
        UnReadCount: x.unread_count,
        MessageStatus: x.messages[0]?.status ? x.messages[0]?.status as ChannelDeliveryStatus : ChannelDeliveryStatus.SENDING,
        LastInboundMessageActivity: x.message_activity,
        OnlineStatus: x?.online_status,
        Ticket: { Assigned_To: x?.ticket.assigned_to, Status: x?.ticket.status, Priority: x?.ticket.status, History: x?.ticket.history }
    } as ChannelConversationItem;
}

export const handlePushGroupChatName = (state: AppState, id, name): AppState => {

    let { Conversations } = state;

    let con = Conversations.find(y => y.GroupChat?.ID === id)
    if (con) {
        con.GroupChat.Name = name;
    }
    return {
        ...state, Conversations: [...Conversations]
    }
}


export { ChannelType };
export class ConversationModel extends BaseConversationItem {
    IsOutbound: boolean;
    FromPhoneNumber?: string;
    PhoneNumber: string;
    MaskedPhoneNumber: string;
    Media?: MediaModel;
    Contact?: Contact;
    IsPinned: boolean;
    IsBlocked: boolean;
    IsUnsubscribed: boolean;
    DeliveryStatus: DeliveryStatus;
    IsMuted: boolean;
    GroupChat?: GroupChatModel;
    GroupChatId?: string
}

export class ChannelContactModel {
    ID: string;
    OrganizationId: string;
    ChannelId: string;
    PhoneId: string;
    FacebookId: string;
    InstagramId: string;
    GoogleId: string;
    WhatsappId: string;
    LiveChatId: string;
    PrimaryChannel: number;
    FirstName: string;
    LastName: string;
    Email: string;
    ProfilePicLink: string;
    Gender: string;
    Location: string;
    UpdatedBy: string;
    CreatedDateTime: string;
    UpdatedAt: string;
    IsArchived: string;
    UnreadCount: number;
    LastMessageActivity: string;
    CustomFields: CustomFieldModel[];
}

export class CustomFieldModel {
    ID: string;
    Name: string;
    Value: string;
    Type: string;
    View: boolean;
}

export const mapToChannelContactItem = (x: any) => {
    return {
        ID: x.id,
        IsArchived: x.is_archived,
        UnreadCount: x.unread_count,
        OrganizationId: x.tenant_id,
        ChannelId: x.account_id,
        PhoneId: x.data.phone.mp_id,
        FacebookId: x.data.facebook.mp_id,
        InstagramId: x.data.instagram.mp_id,
        LiveChatId: x.data.livechat.mp_id,
        WhatsappId: x.data.whatsapp.mp_id,
        GoogleId: x.data.google.mp_id,
        PrimaryChannel: x.primary,
        FirstName: x.primary_data?.first_name || "",
        LastName: x.primary_data?.last_name || "",
        Email: x.primary_data?.email || "",
        ProfilePicLink: x.primary_data?.profile_pic || "",
        Gender: x.primary_data?.user_gender || "",
        Location: x.primary_data?.user_location || "",
        UpdatedBy: x.updated_by,
        UpdatedAt: x.updated_at,
        CreatedDateTime: x.created_at,
        LastMessageActivity: x.message_activity,
        CustomFields: x.custom_fields?.map(mapToCustomFields)
    }
}

export const mapToCustomFields = (x: any): CustomFieldModel => {
    return {
        ID: x.id,
        Name: x.name,
        Value: x.value,
        Type: x.type,
        View: x.view
    }

}

export enum CustomFieldTypes {
    TEXT = 1,
    NUMBER,
    DATE_TIME
}

// export type ConversationCardModel = IConversationCardFlags & SmsConversationItem;